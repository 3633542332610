<template>
	<div class="detail">
		<BACK title="" classStyle="style2" :isMore="true" :backFun="backFun" :tid="detail.tid" :pid="detail.pid"></BACK>
		<div class="contentBox commBlock">
			<div class="banner" v-if="user_groupid&&detail.coverImg">
				<img :src="detail.coverImg" />
			</div>
			<div class="contentDiv">
				<div class="head">
					<HEADLINE :author="detail.nickname" :dateline="detail.dateline" :authorid="detail.authorid"
						:avatar="detail.avatar?detail.avatar:''" :user_groupid="detail.user_groupid"
						:is_follow="detail.is_follow"></HEADLINE>
				</div>
				<div class="contextTitle" v-if="user_groupid">{{detail.title}}</div>
				<div @click="previewImg($event)" id="htmlDiv" class="htmlDiv" :class="user_groupid?'':'nogf'"
					ref="htmlDiv" v-html="detail.message">
				</div>
				<TAGLINE v-if="detail.classname" :smData="['#'+detail.classname]"></TAGLINE>
			</div>
		</div>
		<div class="pinglunList">
			<PINGLUNLIST ref="pinglunlist" :tid="tid" :total="parseInt(detail.reply_num)" :pinglunList="pinglunList">
			</PINGLUNLIST>
		</div>
		<!--底部评论-->
		<PINGLUN v-if="loaded" :reply_num="parseInt(detail.reply_num)"  :like_num="parseInt(detail.like_num)" :pid="detail.pid" :index="index" from="detail"
			:dolike="detail.is_like" :favorite="parseInt(detail.is_favorite)" :tid="detail.tid"
			:authorid="detail.authorid"></PINGLUN>

		<!--评论-->
		<div class="answerpinglunBox">
			<van-popup :show="showPinglun" round position="bottom" :style="{ 'max-height': '3.8rem'}"
				@click-overlay="closePinglunAlert" :close-on-click-overlay="true">
				<div class="pinglunAlert">
					<div class="pinglunDiv">
						<div class="pinglunhead"><img :src="userInfo.avatar" /></div>
						<div class="pinlunInput">
							<van-field v-model="pinglungcont" rows="1" autosize type="textarea"
								:placeholder="preTishi" />
						</div>
					</div>
					<div class="pinglunFun">
						<van-button class="pinglunBtn" @click="pinglunFun" v-preventReClick="1500">评论</van-button>
					</div>
				</div>
			</van-popup>
		</div>
	</div>

</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import BACK from '@/components/back/back.vue';
	import HEADLINE from '@/components/headLine/headLine.vue';
	import PINGLUN from '@/components/pinglun/pinglun.vue';
	import TAGLINE from '@/components/tagLine/tagLine.vue';
	import PINGLUNLIST from '@/components/pinglun/pinglunList.vue';
	import request from '@/service/request';
	import {
		ImagePreview,
		Popup,
		Button,
		Field,
		Toast
	} from 'vant';
	import common from '@/util/common';

	export default defineComponent({
		name: 'content',
		components: {
			BACK,
			HEADLINE,
			TAGLINE,
			PINGLUNLIST,
			PINGLUN,
			[ImagePreview.Component.name]: ImagePreview.Component,
			[Popup.name]: Popup,
			[Button.name]: Button,
			[Field.name]: Field
		},

		props: {
			tid: {
				default: '',
				type: String
			},
			history: {
				default: '',
				type: String
			},
			showPL: {
				default: false,
				type: Boolean
			},
			index: {
				default: 0,
				type: Number
			}
		},
		data() {
			return {
				detail: {},
				pinglunList: [],
				map: [],
				isLoadIMG: false,
				user_groupid: false,
				userIcon: '',
				pinglungcont: '',
				showPinglun: false,
				loaded: false,
				preTishi: '添加回帖~',
				userInfo: {}
			}
		},
		created() {
			console.log("created--detail")
			this.getDetail();
			this.showPinglun = this.showPL;
			var userInfo = window.localStorage.getItem('userInfo');
			if (userInfo) {
				this.userInfo = JSON.parse(userInfo)
			}
		},
		methods: {
			getDetail() {
				request.apiAxios({
					method: 'post',
					url: request.VIEWTHREAD,
					data: {
						tid: this.tid,
						page: 1
					}
				}).then((res) => {
					if (res.status) {
						console.log(res);
						this.detail = res.data.data.thread;
						this.user_groupid = this.detail.user_groupid == '1' ? true : false;
						this.pinglunList = res.data.data.list;
					}
					this.loaded = true;
				}).catch((err) => {
					console.log(err);
				})
			},
			previewImg($event) {
				console.log(this.$refs.htmlDiv)
				//遍历内容里面的图片
				this.fds(document.getElementById('htmlDiv'));
				this.isLoadIMG = true;
				if ($event.target.currentSrc) {
					var index = 0;
					for (var i = 0; i < this.map.length; i++) {
						if (this.map[i] == $event.target.currentSrc) {
							index = i;
							break;
						}
					}
					ImagePreview({
						images: this.map,
						startPosition: index,
					});
					console.log($event.target.currentSrc);
				}
			},
			fds(node) {
				if (this.map && !this.isLoadIMG) {
					if (node.nodeType === 1) {
						//这里我们用nodeName属性，直接获取节点的节点名称
						var tagName = node.nodeName;
						//判断对象中存在不存在同类的节点，若存在则添加，不存在则添加并赋值为1
						console.log(tagName)
						if ((tagName == 'img' || tagName == "IMG") && node.src) {
							this.map.push(node.src);
						}
					}
					//获取该元素节点的所有子节点
					var children = node.childNodes;
					for (var i = 0; i < children.length; i++) {
						//递归调用
						this.fds(children[i])
					}
				}
			},
			backFun() {
				if (this.$parent.closeDeatil)
					this.$parent.closeDeatil();
			},
			//发表评论
			pinglunFun() {
				if (common.isLogin()) {
					if (this.curpid) {
						this.replayTieFun();
					} else {
						var smData = {};
						smData.token = window.localStorage.getItem('token');
						smData.tid = this.tid;
						smData.message = this.pinglungcont;
						if (!smData.message) {
							Toast("请输入评论内容！")
							return;
						}
						request.apiAxios({
							method: 'post',
							url: request.REPLYTHREAD,
							data: smData
						}).then((res) => {
							if (res.status) {
								console.log(res);
								Toast("回帖成功！");
								this.pinglungcont = "";
								this.closePinglunAlert();
								this.detail.reply_num=parseInt(this.detail.reply_num)+1;
								this.$parent.modifyReplyNum(this.index, this.detail.reply_num);								
								this.$refs.pinglunlist.replayTieSuccess(res);
							}else{
								Toast(res.message);
							}
						}).catch((err) => {
							console.log(err);
						})
					}
				}
			},
			closePinglunAlert() {
				this.showPinglun = false;
			},
			showPinglunAlert() {
				this.curpid = '';
				this.at_uid = '';
				this.preTishi = '添加回帖~';
				this.showPinglun = true;
			},
			modifyDianZan(index, value) {
				if (this.$parent.modifyDianZan)
					this.$parent.modifyDianZan(index, value);
			},
			modifyShouchang(index, value) {
				if (this.$parent.modifyShouchang)
					this.$parent.modifyShouchang(index, value);
			},
			//回复帖子
			showReplay(pid, at_uid, preTishi) {
				this.showPinglunAlert();
				this.curpid = pid;
				this.at_uid = at_uid;
				this.preTishi = preTishi;
			},
			replayTieFun() {
				var smData = {};
				smData.token = window.localStorage.getItem('token');
				smData.pid = this.curpid;
				smData.at_uid = this.at_uid;
				smData.message = this.pinglungcont;
				if (!smData.message) {
					Toast("请输入评论内容！")
					return;
				}
				request.apiAxios({
					method: 'post',
					url: request.REPLYPOST,
					data: smData
				}).then((res) => {
					if (res.status) {
						console.log(res);
						Toast("回帖成功！");
						this.pinglungcont = "";
						this.closePinglunAlert();
						this.preTishi = '说点什么吧';
						this.$refs.pinglunlist.replayTieChildSuccess(res);
					}else{
						Toast(res.message);
					}
				}).catch((err) => {
					console.log(err);
				})
			},



		}
	})
</script>

<style lang="less" scoped="scoped">
	.detail {
		padding: 0.26rem 0.26rem 0;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
		z-index: 1001;
		background: linear-gradient(0deg, #EDEDD0, #DBEFE7);
	}

	.banner {
		img {
			max-width: 100%;
		}
	}

	.head {
		margin-bottom: 0.25rem;
	}

	.contentBox {
		margin-bottom: 0.25rem;
	}

	.contextTitle {
		color: #5D6262;
		font-size: 0.3rem;
		margin-bottom: 0.3rem;
		text-align: center;
		font-weight: bold;
	}

	.nogf {
		font-size: 0.3rem;
		color: #5D6262;
		line-height: 0.35rem;
	}
</style>
<style lang="less">
	.pinglunBox {
		padding: 0 0.28rem 0.28rem;

		.pinglunTitle {
			position: relative;
			border-bottom: 1px solid #5D6262;
			padding: 0.28rem 0;
			display: flex;
		}

		.dropDownBox {
			position: absolute;
			right: 0.1rem;
			bottom: 0.01rem;

		}

		.van-dropdown-menu__bar {
			background: transparent;
			box-shadow: none;
		}
	}

	.contentDiv {
		padding: 0.2rem;

		.htmlDiv {
			margin-bottom: 0.25rem;
			word-break: break-all;
		}
	}

	.nogf {
		.img_div {
			margin-top: 0.3rem;
			
		}

		img {
			width: 28% !important;
			margin-right: 0.3rem;
			margin-bottom: 0.3rem;
			vertical-align: top;
			border-radius: 0.14rem;
		}
	}

	.van-image-preview {
		z-index: 10001 !important;
	}

	.pinglunAlert {
		display: flex;
		flex-direction: column;
		padding: 0.4rem 0.4rem 1rem;
		position: relative;

		.pinglunDiv {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 0.2rem;
			padding-left: 0.88rem;
			
			.pinglunhead {
				width: 0.64rem;
				height: 0.64rem;
				border-radius: 0.1rem;
				flex: none;
				margin-right: 0.24rem;
				background: #EBEDDF;
				position: absolute;
				left: 0.4rem;
				top: 0.4rem;

				img {
					max-width: 100%;
					max-height: 100%;
					border-radius: 0.1rem;
				}
			}

			.pinlunInput {
				flex: auto;
				background: #E6E7DF;
				border-radius: 0.07rem;
				max-height: 2rem;
				overflow: auto;
				min-height: 0.64rem;
				.van-cell {
					background: #E6E7DF;
					border-radius: 0.07rem;
					padding: 0.1rem;
				}
			}
		}

		.pinglunFun {
			flex: none;
			text-align: right;
			position: absolute;
			bottom: 0.2rem;
			left: 0;
			right: 0.4rem;
			height: 0.8rem;
		}

		.pinglunBtn {
			padding: 0.15rem 0.3rem 0.12rem;
			background: #C0EBE2;
			box-shadow: 0px 1px 3px 0px rgba(161, 161, 136, 0.36);
			border-radius: 0.46rem;
			text-align: center;
			line-height: 0.3rem;
			height: auto;
			color: #20636C;
			font-size: 0.3rem;
			border: none;
		}
	}
</style>
