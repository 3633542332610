<template>
	<div class="messagePage">
		<BACK title="评论/回复" classStyle="style2" :history="history"></BACK>
		<van-list v-model:loading="loading" :finished="finished" :finished-text="finishTxt" @load="loadData">
			<div class="main">
				<div class="messageLine" v-for="(item,index) in listData" :key="index" @click="goDeatil(item.tid,item.authorid)">
					<div class="messageLeft">
						<div class="messageImg"><img :src="item.avatar" /></div>
						<div class="tiezhuzhe">
							<div class="name">
								{{item.author}} 回复了你的评价
							</div>
							<div class="des">{{item.message}}</div>
						</div>
					</div>
					<div class="time">{{timeChange(item.dateline)}}</div>
				</div>
			</div>
		</van-list>
	</div>
	<DETAIL v-if="showDetail" :tid="detailTid" :authorid="detailAuthorid" ></DETAIL>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import BACK from '@/components/back/back.vue';
	import {List} from 'vant';
	import request from '@/service/request'
	import DETAIL from '@/components/detail/detail.vue';
	
	export default defineComponent({
		name: 'content',
		components: {
			BACK,
			[List.name]: List,
			DETAIL,
		},
		mounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #EDEDD0, #DBEFE7);");
		},
		unmounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		},
		data() {
			return {
				listData: [],
				loading: false, //是否处于加载状态，加载过程中不触发 load 事件
				page: 1,
				finishTxt: "没有更多了",
				finished: false,
				showDetail:false,
				detailTid:'',
				detailAuthorid:''
			}
		},
		computed: {
			timeChange() {
				return function(dateline) {					
					var date1=new Date(parseInt(dateline) * 1000);
					var date2=new Date().getTime(); 
					var date3=date2-date1 //时间差的毫秒数 
					//计算出相差天数 
					var days=Math.floor(date3/(24*3600*1000))
					//计算出小时数 
					var leave1=date3%(24*3600*1000)
					var hours=Math.floor(leave1/(3600*1000)) 
					//计算相差分钟数 
					var leave2=leave1%(3600*1000)    //计算小时数后剩余的毫秒数 
					var minutes=Math.floor(leave2/(60*1000)) 
					//计算相差秒数 
					var leave3=leave2%(60*1000)   //计算分钟数后剩余的毫秒数 
					var seconds=Math.round(leave3/1000) 
					if(days>0){
						var Y = date1.getFullYear() + '-';
						var M = (date1.getMonth() + 1 < 10 ? '0' + (date1.getMonth() + 1) : date1.getMonth() +
							1) + '-';
						var D = date1.getDate() + ' ';
						return Y + M + D
					}else if(hours>0){
						return hours+"小时前"
					}else if(minutes>0){
						return minutes+"分钟前"
					}else if(seconds>0){
						return seconds+"秒前"
					}
				}
				//return this.dateline+'时间'
			}
		},
		created() {
			this.history = this.$route.query.history;
			this.getList();
		},
		methods: {
			loadData() {
				if (this.page != 1)
					this.getList();
			},
			getList() {
				request.apiAxios({
					method: 'post',
					url: request.REPLYLIST,
					data: {
						token: window.localStorage.getItem('token'),
						page: this.page
					}
				}).then((res) => {
					if (res.status) {
						if (res.data.list.length > 0) {
							if (this.listData.length == 0) {
								this.listData = res.data.list;
							} else {
								this.listData = this.listData.concat(res.data.list);
							}
							this.finished = false;
						} else {
							this.finished = true;
						}
						this.page++;
					}
					this.loading = false;
				}).catch((err) => {
					console.log(err);
					this.finished = true;
				})
			},
			goDeatil(tid,authorid){
				this.showDetail=true;
				this.detailTid=tid;
				this.detailAuthorid=authorid;		
			},
			closeDeatil(){
				this.showDetail=false;
				this.detailTid='';
				this.detailAuthorid='';
			},
		}
	})
</script>

<style lang="less" scoped="scoped">
	.messagePage {
		padding: 0.26rem 0.26rem 0;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0.2rem;
		overflow-y: auto;

		.messageLine {
			display: flex;
			align-items: center;
			margin-bottom: 0.26rem;
			background: #F8FAF5;
			box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
			border-radius: 0.14rem;
			padding: 0.16rem 0.22rem;

		}

		.messageLeft {
			margin-right: 0.2rem;
			flex: auto;
			display: flex;
			color: #5D6262;
		}

		.messageImg {
			width: 1.06rem;
			height: 1.06rem;
			background: #EBEDDE;
			border-radius: 0.14rem;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 0.2rem;
			flex: none;

			img {
				max-width: 100%;
				max-height: 100%;
				border-radius: 0.07rem;
			}
		}

		.tiezhuzhe {
			display: flex;
			flex-direction: column;
			flex: auto;
			overflow: hidden;
			white-space: nowrap;
			max-width: 3.5rem;

			.name {
				display: flex;
				align-items: center;
				font-size: 0.34rem;
				margin-bottom: 0.14rem;
				color: #5D6262;
				overflow: hidden;

			}

			.des {
				font-size: 0.25rem;
				color: #959B9B;
			}
		}

		.time {
			font-size: 0.25rem;
			color: #959B9B;
			flex: none;
		}
	}
</style>
